function setDom(str, value) {
  window.document.getElementById(str).innerHTML = value;
}
function getPrelogTemp() {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    if (xhr.status >= 200 && xhr.status < 300) {
      document.getElementById("temp").innerHTML = xhr.response;
    } else {
      console.log("The request failed!");
    }
  };
  xhr.open("GET", "/prelog");
  xhr.send();
}
const days = ["Ne", "Po", "To", "Sr", "Če", "Pe", "So", "Ne"];
setInterval(function () {
  const d = new Date();
  let h = d.getHours();
  if (h < 10) h = "0" + h;
  let m = d.getMinutes();
  if (m < 10) m = "0" + m;
  setDom("clock_h", h);
  setDom("clock_m", m);
  setDom("date", days[d.getDay()] + " " + d.getDate() + ".");
}, 500);

setInterval(() => {
  getPrelogTemp();
}, 60000);
getPrelogTemp();
